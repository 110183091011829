import axios from "axios";

// Production-Mirror (Staging) - for internal testing
export const BASE_URL = "https://dectecwebapi.azurewebsites.net/api/";


export async function restGet(apiURL, headers) {
    try {
        const res = await axios.get(BASE_URL + apiURL, { headers });
        if (res && res.data && res.data.status && res.data.status === true)
            return res.data.data;

        // Special case for IsValidResetCode GET
        // The response.data is a string with either "OK" or "Not a valid Email address !", instead of the
        // typical {data, message, status} so the normal check is not appropriate
        else if (res?.data === "OK")
            return true;
        else if (res?.data === "Not a valid Email address !")
            return false;
        else if (res?.data === "Invalid reset code!")
            throw res.data;
        else if (res?.data === "Verification code is invalid!" || res?.data === "Invalid email !")
            throw res.data;
        else if (res?.data === "Error ! Cannot generate reset code ! See logs for details !")
            return false;
        else if (res.data !== '' && typeof res.data === 'string') return res.data;
        else throw res.data.message;
    }
    catch (error) {
        throw (error);
    }
}


// TODO This was changed to return res.data instead of res.data.data; do the same for restGet and
// restDelete as well (will also need to change all API calls using those axios functions)
export async function restPost(apiURL, data, headers) {
    try {
        const res = await axios.post(BASE_URL + apiURL, data, { headers });
        if (res && res.data && res.data.status && res.data.status === true)
            return res.data;
        else {
            throw res.data.message
        }
    }
    catch (error) {
        throw (error);

    }
}


export async function restDelete(apiURL, headers) {
    try {
        const res = await axios.delete(BASE_URL + apiURL, { headers });
        if (res && res.data && res.data.status && res.data.status === true)
            return res.data.data;
        else
            throw res.data.message;
    }
    catch (error) {
        throw error;
    }
}
