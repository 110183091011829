import React from 'react'
import "./../css/Footer.css"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { NavLink} from 'react-router-dom'



const Footer = () => {
    return (
        <div className='main-div'>
        <Container maxWidth="lg">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item lg={3} md={3} xs={12} sm={12}>
                    <div className='dectec-logo-div'>
                        <div className='dectec-logo'> 
                        <a href="/">                
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-header.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-header.png" alt='logo' style={{width:"200px"}} />
                        </a>   
                        </div>
                        <div className='dectec-logo-desc'>
                            <p>
                                Copyright 2023 Skyline Building Systems Inc.
                            </p>
                        </div>
                    </div>
                </Grid>

                <Grid item lg={6} md={6} xs={12} sm={12}>
                    <div className='address-div'>
                        <div className='address-div-first'>
                            <p><b>Calgary Head Office</b></p>
                        </div>
                        <div className='address-div-second'  sx={{ display: { xs: 'inline', sm: 'flex' }}}>
                            <div className='address-div-second-phone'>
                                <p>
                                    <b>T: </b>
                                    1-866-461-3914
                                </p>
                                <p>
                                    <b>E: </b>
                                    <NavLink style={{color:"white"}} to="mailto:sales@dec-tec.com">sales@dec-tec.com</NavLink>
                                </p>
                            </div>
                            <div className='address-div-second-email'>
                                <p className='address-div-second-email-first'>261185 Wagon Wheel Way</p>
                                <p>  Rocky View, AB T4A 0E2</p>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item lg={3} md={3} xs={12} sm={12}>
                    <div className='nadra-logo-div'>
                    <div>
                            <p>PROUD MEMBER OF :</p>
                        </div>
                        < div className='nadra-logo'>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/logo/Logo-NADRA-PNG-250px-1.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/logo/Logo-NADRA-PNG-250px-1.png" style={{width:"200px"}} alt='logo'/>
                        </div>
                        <div className='address-div-first'>
                            <p>
                                <span>
                                <NavLink style={{color:"white"}} to="/privacy-policy/">Privacy Policy </NavLink>| <NavLink style={{color:"white"}} to="/terms-of-service/">Terms and Conditions</NavLink>
                                </span>
                            </p>
                        </div>
                       
                    </div>
                </Grid>
            </Grid>
        </Container>
    </div>
    )
}

export default Footer